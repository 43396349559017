<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />

        <v-card class="card">
            <v-card-title class="heading">Import Library Department
            </v-card-title>
            <!-- <v-card class="box">
                    <div class="flex">
                    <div></div>
                        <div>
                            <a href="https://easypariksha.s3.ap-south-1.amazonaws.com/import_excel_csv_file/Import_Instructor.csv">
                            <v-btn :loading="loading3" :disabled="loading3" color="success" class="ma-2 white--text downbtn" @click="loader = 'loading3'">
                                <v-icon left dark>mdi-cloud-download</v-icon>Download import CSV format
                            </v-btn>
                            </a>
                        </div>
                    </div>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" lg="4" sm="12">
                                <v-file-input dense v-model="file" :hint="file != null? '':'Please Select File'" persistent-hint color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined show-size> </v-file-input>
                            </v-col>
                            <v-col cols="4"> </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="4">
                                <center>
                                    <v-btn @click="importCsv" :loading="output_load" class="button">
                                        Upload
                                        <v-icon right>mdi-cloud-upload</v-icon>
                                    </v-btn>
                                </center>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> -->
                <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <div class="add-section">
                <v-row class="justify-content-between set-btn">
                    <div><a
                            href="https://library-public.s3.ap-south-1.amazonaws.com/sample_csv/import_LibDepartment_csv.csv">
                            <v-btn class="mb-2 button"> <v-icon left dark>mdi-cloud-download</v-icon>Export CSV
                                Template</v-btn></a>
                    </div>
                    <div>
                        <v-btn class="button mb-2" @click="openDialog"> <v-icon left>mdi-account-plus</v-icon>Add New
                            Library Department</v-btn>
                    </div>
                </v-row>
                <p class="file-warn">NOTE: *File should be in CSV format.</p>

                <v-row class="justify-content-center">
                    <v-col lg="5" sm="12" md="5" class="import-box">
                        <label class="label">IMPORT CSV</label>
                        <v-file-input dense v-model="file" :hint="file != null ? '' : 'Please Select File'" persistent-hint
                            color="rgb(1, 127, 152)" label="Choose File" accept=".csv" prepend-icon="mdi-paperclip" outlined
                            show-size>
                        </v-file-input>
                        <v-btn @click=" importCsv()" :loading="output_load" class="button">
                            Upload
                            <v-icon right>mdi-cloud-upload</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            </div>
            <template>
                <v-simple-table v-if="failList != null">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">
                                    Library Department
                                </th>
                                <th class="text-left">Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in failList" :key="item.name">
                                <td>{{ item.name }}</td>
                                <td style="color: red;">{{ item.reason }}</td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </template>

            <!--   <template v-if="load==true">
                <v-data-table :headers="headers" :items="author_list" sort-by class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <download-excel class="mar" :data="emplist" :fields="export_table_headers" worksheet="statuslist" name="Instructor Data.xls">
                               <a href="https://easycheck.s3.ap-south-1.amazonaws.com/library_sample_csv/library_import_emp_member_csv.csv">
                                <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn></a>
                            </download-excel>
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                            <span style="width: 20px;"></span>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive==true">
                            <v-icon class="mr-2" color="success" @click="toggle(item)">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                            <v-icon class="mr-2" color="error" @click="toggle(item)">mdi-close-circle-outline</v-icon>
                        </span>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <div>                         
                                <v-icon size="18" @click="editDialogFunction(item)">mdi mdi-pencil-box</v-icon>                         
                        </div>
                    </template>
                </v-data-table>
            </template>-->
            <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <template v-if="libDepartmentlist != null">
                <v-data-table :headers="headers" :items="libDepartmentlist" :search="search" class="elevation-1 nowrap">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                hide-details></v-text-field>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.edit="{ item }">
                        <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                        <v-icon v-if="item" class="mr-2" @click="updateBookVendor(item)">mdi mdi-pencil-box</v-icon>
                    </template>
                    <template v-slot:item.delete="{ item }">
                        <v-icon v-if="item" class="mr-2" @click="deleteLibraryDepartment(item)"> mdi mdi-delete</v-icon>
                    </template>
                </v-data-table>
            </template>
</div>
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card>
                    <v-card-title class="heading">
                        <span>Add Library Department</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <label class="add-text"> Library Department </label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="libDepartment" outlined dense></v-text-field>
                                    <label class="add-text">Library Department Description</label>
                                    <v-text-field class="text" v-model="description" outlined dense></v-text-field>
                                    <label class="add-text">ERP Department</label>
                                    <v-select dense v-model="selecteddept" :items="erpDepartmentList"
                                        item-value="erpDepartment" item-text="erpDepartment" persistent-hint
                                        outlined></v-select>
                                    <label class="add-text">Add Display Order</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" type="number" v-model="displayorder" outlined
                                        dense></v-text-field>
                                    <label class="add-text">Add Short Name</label>
                                    <v-text-field class="text" v-model="shortname" outlined dense></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="button-cancel" text @click="close()">Close</v-btn>
                        <v-btn class="button" text @click="addInst">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="editDialog" width="500">
                <v-card>
                    <v-card-title class="grey lighten-2">
                        Edit Library Department
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px;">
                        <label class="add-text">Library Department</label><span style="color: red;"> *</span>
                        <v-text-field class="text" v-model="libDepartment" outlined dense></v-text-field>
                        <label class="add-text"> Vendor Type Description</label>
                        <v-text-field class="text" v-model="description" outlined dense></v-text-field>
                        <label class="add-text">ERP Department</label>
                        <v-select dense v-model="selecteddept" :items="erpDepartmentList" item-value="erpDepartment"
                            item-text="erpDepartment" persistent-hint outlined></v-select>
                        <label class="add-text">Add Display Order</label><span style="color: red;"> *</span>
                        <v-text-field class="text" type="number" v-model="displayorder" outlined dense></v-text-field>
                        <label class="add-text">Add Short Name</label>
                        <v-text-field class="text" v-model="shortname" outlined dense></v-text-field>
                    </v-card-text>

                    <!-- <v-divider></v-divider> -->

                    <div class="text-center">
                        <v-spacer></v-spacer>
                        <v-btn class="m-3" @click="close()">
                            Close
                        </v-btn>
                        <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </v-card>


    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    /*
  Defines the data used by the component
*/
    data() {
        return {
            emplist: [],
            init_loading: false,
            loader: false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '/home',
                },
                {
                    text: localStorage.getItem("jhhjbyj"),
                    disabled: false,
                    href: '/sublink',
                },
                {
                    text: 'Admin Master',
                    disabled: false,
                    href: '/admin-master',
                },
                {
                    text: 'Library Department',
                    disabled: true,
                    href: '',
                },
            ],
            org: null,
            dialog: false,
            search: "",
            libDepartment: null,
            libDepartmentId: null,
            edit_btn_load: false,
            shortname: null,
            description: null,
            displayorder: null,
            erpDepartment: null,
            erpDepartmentList: null,
            libDepartmentlist: null,
            selecteddept: null,
            file: null,
            statuslist: "",
            load: false,
            loading3: false,
            search: "",
            failList: null,
            editDialog: false,
            InstRecord: "",
            isLoading: false,

            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Library Department", value: "libDepartment" },
                { text: "ERP Department", value: "erpdepartment" },
                { text: "Description", value: "description" },
                { text: "Short Name", value: "shortname" },
                { text: "Edit", value: "edit" },
                { text: "Delete", value: "delete" },
            ],
            output_load: false,
        };
    },

    mounted() {
        this.onload();
    },
    methods: {
        close() {

            this.editDialog = false;
            this.dialog = false;

        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        openDialog() {
            this.dialog = true;
            this.libDepartment = '';
            this.libDepartmentId = '';
            this.description = '';
            this.displayorder = '';
            this.shortname = '';
            this.selecteddept = '';
        },
        updateBookVendor(item) {
            console.log(item);
            this.editDialog = true;
            this.libDepartment = item.libDepartment;
            this.libDepartmentId = item.libDepartmentId;
            this.description = item.description;
            this.displayorder = item.displayorder;
            this.shortname = item.shortname;
            this.selecteddept = item.erpdepartment;
            this.loader = true;
            axios
                .post("/admin/getLibraryDepartment")
                .then((res) => {
                    this.loader = false;
                    this.erpDepartmentList = res.data.erpDepartmentList
                    //this.overlay = false
                })
                .catch((error) => {
                    this.loader = false;
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.loader = false
                });
        },
        saveEdit(item) {
            if(!this.libDepartment){
                this.showSnackbar("#b71c1c", "Please Enter Library Department");
                return;
            }
            else if(!this.displayorder){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
            this.dialog = false;
            const data = {
                libDepartment: this.libDepartment,
                libDepartmentId: this.libDepartmentId,
                description: this.description,
                displayorder: this.displayorder,
                shortname: this.shortname,
                erpDepartment: this.selecteddept,
            };
                this.loader = true;
                axios
                    .post("/admin/editLibraryDepartment", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.loader = false;
                            // this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Library Department updated successfully...");
                            this.onload();
                            this.editDialog = false;

                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.loader = false;
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
          
        },
        deleteLibraryDepartment(item) {
            const data = {
                libDepartmentId: item.libDepartmentId,
            };
            axios
                .post("/admin/deleteLibraryDepartment", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Library Department  Deleted Successfully  !!");
                        this.onload();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getLibraryDepartment")
                .then((res) => {
                    this.org = res.data.org;
                    this.erpDepartmentList = res.data.erpDepartmentList;
                    this.libDepartmentlist = res.data.libDepartmentlist
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addInst() {
            if(!this.libDepartment){
                this.showSnackbar("#b71c1c", "Please Enter Library Department");
                return;
            }
            else if(!this.displayorder){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
                this.dialog = false;
                const data = {
                    libDepartment: this.libDepartment,
                    libDepartmentId: this.libDepartmentId,
                    description: this.description,
                    displayorder: this.displayorder,
                    shortname: this.shortname,
                    erpDepartmentList: this.selecteddept,
                    
                };
                this.loader = true;
                axios
                    .post("/admin/addLibraryDepartment", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.loader = false;
                            this.showSnackbar("#4CAF50", "Library Department added successfully...");
                            // this.userType = null;

                            this.onload();
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                            // this.$refs.form.validate()
                        }
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { 
                        this.loader = false;
                    });
        },

        importCsv() {

            let formData = new FormData();
            if (this.file !== null) {
                if (!(this.file.name.toLowerCase().includes(".csv") || this.file.name.toLowerCase().includes(".CSV"))) {
                    this.showSnackbar("red", "File must be in CSV format Only.!");
                } else {
                    this.fileStatus = true;
                    formData.append("file", this.file);
                    axios
                        .post("admin/importLibraryDepartment", formData, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        })
                        .then((res) => {
                            if (res.data.status == "200") {
                                this.showSnackbar("#4caf50", "Library Department added successfully...");
                                this.onLoad();
                            }
                            this.file = null;
                            //this.onLoad();
                            this.failList = res.data.failList;
                        })
                        .catch(function () {
                            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        })
                        .finally(() => {
                            this.output_load = false;
                        });
                }
            } else {
                this.fileStatus = false;
                this.showSnackbar("#b71c1c", "Please select file");
            }
        },
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        checkuser() {
            alert("check user name");
        },
        checkExtention() {
            if (this.file != null) {
                var file = this.file.name;
                var ext = file.split(".").pop();
                if (ext == "csv") {
                    return true;
                } else {
                    // console.log("false")
                    // alert("Upload only CSV file!");
                    return false;
                }
            } else {
                return false;
            }
        },
    },
};
</script>

<style scoped>
.downbtn {
    float: right;
}

.add-section {
    display: block;
}

.box {
    background-color: #add8e630;
    margin: 8px !important;
    padding: 8px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 600px) {
    .flex {
        display: block;
    }
}

.btn {
    margin: 1rem;
    padding-top: 1rem;
}
</style>
